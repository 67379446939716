import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config';
import { useNavigate } from 'react-router-dom';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';

import {
  useTheme,
  useMediaQuery,
  Typography,
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Button,
  ListItemIcon,
  Box,
  AppBar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { LocationOn, Schedule, Work, Money, People } from '@mui/icons-material';

const HOST_ADDRESS = config.apiUrl;

const Jobs = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [jobList, setJobList] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [jobSkillList, setJobSkillList] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);

  useEffect(() => {
    sessionStorage.removeItem('ats-token');
    const fetchData = async () => {
      try {
        const jobData = await axios.get(`${HOST_ADDRESS}/job/all`);
        const skillData = await axios.get(`${HOST_ADDRESS}/skill/all`);
        const jobSkillData = await axios.get(`${HOST_ADDRESS}/job_skill/all`);
        setJobList(jobData.data.jobs);
        setSkillList(skillData.data.skills);
        setJobSkillList(jobSkillData.data.job_skill);
        sessionStorage.removeItem('ats-token');
      } catch (error) {
        console.error('Error fetching job list:', error);
      }
    };
    fetchData();
  }, []);

  const getSkillsForJob = (jobId) => {
    const skills = jobSkillList
      .filter((mapping) => mapping.job_id === jobId)
      .map((mapping) => skillList.find((skill) => skill.id === mapping.skill_id));
    return skills;
  };

  const handleClickOpen = (job) => {
    setSelectedJob(job);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedJob(null);
  };

  const handleApply = () => {
    if (selectedJob) {
      const { id, title } = selectedJob;
      const passkey = '35625e@klio'
      navigate(`/applyform?index=${id}&applyfor=${encodeURIComponent(title)}&passkey=${encodeURIComponent(passkey)}`);
    }
  };

  return (
    <>
      <Box>
        <AppBar position="static">
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <Box display="flex" justifyContent="center" alignItems="center" ml="30px">
              <img
                alt="profile-user"
                width="140px"
                height="140px"
                src={`../../assets/pipluspi_logo.svg`}
              />
            </Box>
            {!isMobile && (
              <Typography variant='h2'>
                <span variant='h1' style={{ color: '#f20000' }}>PIPLUSPI</span> Job Portal
              </Typography>
            )}
            <Box display="flex" justifyContent="center" alignItems="center" mr="30px">
              <Button
                href="https://pipluspi.com/"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  backgroundColor: 'white',
                  color: 'black',
                  border: '1px solid #ccc',
                  padding: '10px 20px',
                  textTransform: 'none',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  '&:hover': {
                    backgroundColor:'white',
                    color: '#f20000'
                  },
                  '&:focus': {
                    outline: 'none',
                    boxShadow: '0 0 0 3px rgba(0, 123, 255, 0.25)',
                  },
                }}
              >
                Visit PIPLUSPI
              </Button>
            </Box>
          </Box>
        </AppBar>
        <Container maxWidth="lg" sx={{ marginTop: '30px' }}>
          {jobList.length === 0 ? (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
              <img
                alt="No Jobs Available"
                width="500px"
                src={`../../assets/loading_1.gif`}
              />
            </Box>
          ) : (
            
            <Grid container spacing={3}>
              {jobList.map((job, index) => (
                <Grid key={index} item xs={12} sm={6} md={4}>
                  <Card sx={{ height: '100%' , boxShadow:'0 0 10px rgba(0, 0, 0, 0.5)'}}>
                    <CardHeader title={job.title} />
                    <CardContent>
                      <Typography variant="body1" color="textSecondary" gutterBottom>
                        <b>{job.description}</b>
                      </Typography>
                      <List sx={{ minWidth: '250px' }}>
                        <ListItem>
                          <ListItemIcon>
                            <Work />
                          </ListItemIcon>
                          <ListItemText
                            primary={`Skills: ${getSkillsForJob(job.id)
                              .map((skill) => skill.name)
                              .join(', ')}`}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <People />
                          </ListItemIcon>
                          <ListItemText primary={`No of Vacancy : ${job.no_of_vacancy}`} />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <LocationOn />
                          </ListItemIcon>
                          <ListItemText primary="Address : C-1231, Siddhi Vinayak Tower, Makaraba, Ahmedabad, GJ, IN" />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <Schedule />
                          </ListItemIcon>
                          <ListItemText primary="Job Type : Full Time & On-Site" />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <Money />
                          </ListItemIcon>
                          <ListItemText
                            primary={`Salary Between : ${job.salary_min} to ${job.salary_max}`}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <WorkHistoryIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={`Experience Between : ${job.exp_min} to ${job.exp_max}`}
                          />
                        </ListItem>
                      </List>
                    </CardContent>
                    <Box sx={{ flexGrow: 1 }} />
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => handleClickOpen(job)}
                      sx={
                        {'&:hover': {
                          backgroundColor: '#f0f0f0',
                          borderColor: '#bbb',
                          color: 'black',
                          
                        },}
                      }
                    >
                      Apply Now
                    </Button>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </Container>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Apply for Job</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to apply for the job: <b>{selectedJob ? selectedJob.title : ''}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleApply} color="primary">
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Jobs;
