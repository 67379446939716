import { Typography, Box, TextField, Button, Grid, Radio, RadioGroup, FormControl, FormControlLabel, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import config from '../../config';
import { Formik, Form, Field, } from 'formik';
import * as yup from 'yup';


const initialValues = {
    first_name: '',
    middle_name: '',
    last_name: '',
    contact_number: '',
    email_id: '',
    alternate_contact: '',
    address: '',
    city_name: '',
    state_name: '',
    zip_code: '',
    gender: '',
    marital_status: '',
    religion: '',
    cast_category: '',
    father_name: '',
    father_occupation: '',
    mother_name: '',
    mother_occupation: '',

    tenth_school_name: '',
    tenth_year_passing: '',
    tenth_percentage: '',
    tenth_board_name: '',
    diploma: '',
    diploma_course_name: '',
    diploma_university_name: '',
    diploma_college_name: '',
    diploma_year_of_passing: '',
    diploma_percentage: '',
    twelve: '',
    twelve_course_name: '',
    twelve_board_name: '',
    twelve_school_name: '',
    twelve_year_of_passing_name: '',
    twelve_percentage: '',
    selected_degree: '',
    degree_name: '',
    degree_type: '',
    degree_course_name: '',
    degree_uiversity_name: '',
    degree_college_name: '',
    degree_passing_year: '',
    degree_percentage: '',
    criminal_case: '',
    institute_prior: '',
    resume_name: '',
    six_month_apply: '',
    language: '',
    fresher: '',
    current_ctc: '',
    current_company: '',
    notice_period: '',
    exceptcted_ctc: '',
    jobId: ''
};

const validateSixMonthApply = (value) => {
    if (!value) {
        return 'Please select an option';
    }
};

const formSchema = yup.object().shape({
    first_name: yup.string().required('First Name is required'),
    middle_name: yup.string().required('Middle Name is required'),
    last_name: yup.string().required('Last Name is required'),
    email_id: yup.string().email('Invalid email format').required('Email is required'),
    contact_number: yup.string().matches(/^[0-9]{10}$/, 'Contact number must be exactly 10 digits').required('Contact Number is required'),
    alternate_contact: yup.string().matches(/^[0-9]{10}$/, 'Alternate number must be exactly 10 digits'),
    address: yup.string().required('Address is required'),
    city_name: yup.string().required('City Name is required'),
    state_name: yup.string().required('State Name is required'),
    zip_code: yup.number().required('Zip Code is required'),
    language: yup.string().required('Language is required'),
    cast_category: yup.string().required('Cast Category is required'),
    marital_status: yup.string().required('Marital Status is required'),
    gender: yup.string().required('Gender is required'),
    notice_period: yup.number(),
    
    diploma_course_name: yup.string(),
    diploma_university_name: yup.string(),
    diploma_college_name: yup.string(),
    diploma_percentage: yup.string(),
    diploma_year_of_passing:  yup.number(),

    tenth_board_name: yup.string(),
    tenth_percentage: yup.number(),
    tenth_school_name: yup.string(),
    tenth_year_passing: yup.number(),
    

    twelve_course_name: yup.string(),
    twelve_board_name: yup.string(),
    twelve_school_name: yup.string(),
    twelve_percentage: yup.number(),
    twelve_year_of_passing_name:  yup.number(),

    degree_college_name: yup.string(),
    degree_course_name: yup.string(),
    degree_name: yup.string(),
    degree_uiversity_name: yup.string(),
    degree_percentage: yup.number(),
    degree_passing_year:  yup.number(),
    degree_type: yup.string(),



});

const HOST_ADDRESS = config.apiUrl;

const ApplyForm = () => {
    const navigate = useNavigate();
    const [diploma, setDiploma] = useState('');
    const [fresher, setFresher] = useState('');
    const [twelve, setTwelve] = useState('');
    const [degree, setDegree] = useState('');
    const location = useLocation();
    const [jobId, setJobId] = useState('');
    const [jobName, setJobName] = useState('');
    
    const [selectedFile, setSelectedFile] = useState(null);



    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const jobId = params.get('index');
        const jobName = params.get('applyfor');
        
        setJobId(jobId);
        setJobName(jobName);
        
        

    
        if (!jobId || !jobName) {
          navigate('/');
        }
      }, [location, navigate]);


    const handleSubmitForm = async (values, { resetForm }) => {
        values.jobId = jobId
        const resumeChangedName = {
            firstName: document.querySelector('input[name="first_name"]').value,
            contactNumber: document.querySelector('input[name="contact_number"]').value,
            jobId: jobId
        }
        const uploadedFile = document.getElementById('resume').files[0];
        if (uploadedFile && uploadedFile.size > 2 * 1024 * 1024) {
            alert('The File size is greater than 2048 Kb');
        } else {
            if (!selectedFile) {
                window.alert('Upload Resume Please!');
                return;
            }
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('data', JSON.stringify(resumeChangedName));

            try {
                const url_resume = HOST_ADDRESS + '/applicationForm/upload'
                axios.post(url_resume, formData);

            } catch (error) {
                console.error('Error uploading file: ', error);
            }
        }
        const url = `${HOST_ADDRESS}/applicationForm/add`;
        try {
            const response = await axios.post(url, values, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200) {
                navigate('/')
                resetForm();
            } else if (response.status === 500) {
                navigate('/')
            }
            else {
                navigate('/')
                window.alert("Apllicaiton is not Submit Please Try Again Later!");
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 500) {

                    navigate('/');
                } else {
                    window.alert("Application is not submitted. Please try again later!");
                    navigate('/');
                }
            } else if (error.request) {
                console.error('No response received:', error.request);
                window.alert("No response received. Please try again later!");
                navigate('/');
            } else {
                console.error('Error in setting up request:', error.message);
                window.alert("An error occurred. Please try again later!");
                navigate('/');
            }
        }
    };
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const arrayBuffer = reader.result;

                if (isPDF(arrayBuffer)) {
                    setSelectedFile(file);
                    updateFileName(file.name);

                } else {
                    window.alert('Uploaded file is not a PDF');
                    event.target.value = null;
                    setSelectedFile(null);
                    updateFileName('');

                }
            };
            reader.readAsArrayBuffer(file);
        }
    };
    const isPDF = (arrayBuffer) => {

        const magicNumber = new Uint8Array(arrayBuffer).slice(0, 4);
        const pdfMagicNumber = [0x25, 0x50, 0x44, 0x46];
        return (
            magicNumber.length === pdfMagicNumber.length &&
            magicNumber.every((value, index) => value === pdfMagicNumber[index])
        );
    };
    const updateFileName = (fileName) => {
        document.getElementById('resume').innerText = fileName;
    };
    return (
        <Box sx={{ margin: '20px', mt: 4, p: 2, border: '0px solid #ccc', borderRadius: 0, boxShadow: 9 }}>
            <Typography variant='h4' sx={{ mt: '15px', mb: '30px' }}>
                Apply Job for <b>{jobName}</b>
            </Typography>
            <Formik
                initialValues={initialValues}
                validationSchema={formSchema}
                onSubmit={handleSubmitForm}
            >
                {({ values, errors, touched, handleBlur, handleChange, isSubmitting }) => (
                    <Form>
                        <Grid container spacing={2}>

                            <Grid container item xs={12} spacing={2} alignItems="center">
                                <Grid item xs={12} sm={3}>
                                    <Typography><b>Have you been interviewed by PIPLUSPI in the last six months</b></Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Field name="six_month_apply" validate={validateSixMonthApply} >
                                        {({ field }) => (
                                            <RadioGroup
                                                {...field}
                                                aria-labelledby="interviewed-last-six-months-radio-buttons-group-label"
                                                name="six_month_apply"
                                                row

                                            >
                                                <FormControlLabel value="Yes" control={< Radio required={true} />} label="Yes" />
                                                <FormControlLabel value="No" control={<Radio required={true} />} label="No" />
                                            </RadioGroup>
                                        )}
                                    </Field>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        InputLabelProps={{
                                            style: { fontWeight: 'bold' },
                                        }}
                                        label='First Name'
                                        variant='outlined'
                                        size='small'
                                        fullWidth
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.first_name}
                                        name='first_name'
                                        error={touched.first_name && Boolean(errors.first_name)}
                                        helperText={touched.first_name && errors.first_name}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        InputLabelProps={{
                                            style: { fontWeight: 'bold' },
                                        }}
                                        label='Last Name'
                                        variant='outlined'
                                        size='small'
                                        fullWidth
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.last_name}
                                        name='last_name'
                                        error={touched.last_name && Boolean(errors.last_name)}
                                        helperText={touched.last_name && errors.last_name}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        InputLabelProps={{
                                            style: { fontWeight: 'bold' },
                                        }}
                                        label='Middle Name'
                                        variant='outlined'
                                        size='small'
                                        fullWidth
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.middle_name}
                                        name='middle_name'
                                        error={touched.middle_name && Boolean(errors.middle_name)}
                                        helperText={touched.middle_name && errors.middle_name}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        InputLabelProps={{
                                            style: { fontWeight: 'bold' },
                                        }}
                                        label='Contact Number'
                                        variant='outlined'
                                        type='tel'
                                        size='small'
                                        fullWidth
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{ maxLength: 10 }}
                                        value={values.contact_number}
                                        name='contact_number'
                                        error={touched.contact_number && Boolean(errors.contact_number)}
                                        helperText={touched.contact_number && errors.contact_number}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        InputLabelProps={{
                                            style: { fontWeight: 'bold' },
                                        }}
                                        label='Email Address'
                                        variant='outlined'
                                        type='email'
                                        size='small'
                                        fullWidth
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.email_id}
                                        name='email_id'
                                        error={touched.email_id && Boolean(errors.email_id)}
                                        helperText={touched.email_id && errors.email_id}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        InputLabelProps={{
                                            style: { fontWeight: 'bold' },
                                        }}
                                        label='Alternate Number'
                                        variant='outlined'
                                        type='tel'
                                        size='small'
                                        fullWidth
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.alternate_contact}
                                        name='alternate_contact'
                                        inputProps={{ maxLength: 10 }}
                                        error={touched.alternate_contact && Boolean(errors.alternate_contact)}
                                        helperText={touched.alternate_contact && errors.alternate_contact}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        InputLabelProps={{
                                            style: { fontWeight: 'bold' },
                                        }}
                                        label='Address'
                                        variant='outlined'
                                        type='text'
                                        size='small'
                                        fullWidth
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.address}
                                        name='address'
                                        error={touched.address && Boolean(errors.address)}
                                        helperText={touched.address && errors.address}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <TextField
                                        InputLabelProps={{
                                            style: { fontWeight: 'bold' },
                                        }}
                                        label='City Name'
                                        variant='outlined'
                                        type='text'
                                        size='small'
                                        fullWidth
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.city_name}
                                        name='city_name'
                                        error={touched.city_name && Boolean(errors.city_name)}
                                        helperText={touched.city_name && errors.city_name}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2} >
                                    <TextField
                                        InputLabelProps={{
                                            style: { fontWeight: 'bold' },
                                        }}
                                        label='State Name'
                                        variant='outlined'
                                        type='text'
                                        size='small'
                                        fullWidth
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.state_name}
                                        name='state_name'
                                        error={touched.state_name && Boolean(errors.state_name)}
                                        helperText={touched.state_name && errors.state_name}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <TextField
                                        InputLabelProps={{
                                            style: { fontWeight: 'bold' },
                                        }}
                                        label='Zip Code'
                                        variant='outlined'
                                        type='tel'
                                        size='small'
                                        fullWidth
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.zip_code}
                                        name='zip_code'
                                        error={touched.zip_code && Boolean(errors.zip_code)}
                                        helperText={touched.zip_code && errors.zip_code}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <TextField
                                        InputLabelProps={{
                                            style: { fontWeight: 'bold' },
                                        }}
                                        label='Proefficient Language'
                                        variant='outlined'
                                        type='text'
                                        size='small'
                                        fullWidth
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.language}
                                        name='language'
                                        error={touched.language && Boolean(errors.language)}
                                        helperText={touched.language && errors.language}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item xs={12} spacing={2}>

                                <Grid item xs={12} sm={3}>
                                    <Field name="gender">
                                        {({ field, form: { touched, errors } }) => (
                                            <FormControl fullWidth variant="outlined" size="small">
                                                <TextField
                                                    InputLabelProps={{
                                                        style: { fontWeight: 'bold' },
                                                    }}
                                                    id="cast-category-select"
                                                    select
                                                    size="small"
                                                    label="Select Your Gender"
                                                    {...field}
                                                    value={field.value || ''}

                                                    error={touched.gender && Boolean(errors.gender)}
                                                    helperText={touched.gender && errors.gender}
                                                >
                                                    <MenuItem value="Male">Male</MenuItem>
                                                    <MenuItem value="Female">Female</MenuItem>

                                                </TextField>
                                            </FormControl>
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Field name="marital_status">
                                        {({ field, form: { touched, errors } }) => (
                                            <FormControl fullWidth variant="outlined" size="small">
                                                <TextField
                                                    InputLabelProps={{
                                                        style: { fontWeight: 'bold' },
                                                    }}
                                                    id="cast-category-select"
                                                    select
                                                    size="small"
                                                    label="Select Your Marital Status"
                                                    {...field}
                                                    value={field.value || ''}

                                                    error={touched.marital_status && Boolean(errors.marital_status)}
                                                    helperText={touched.marital_status && errors.marital_status}
                                                >
                                                    <MenuItem value="Married">Married</MenuItem>
                                                    <MenuItem value="Unmarried">Unmarried</MenuItem>
                                                    <MenuItem value="Divorced">Divorced</MenuItem>

                                                </TextField>
                                            </FormControl>
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Field name="cast_category">
                                        {({ field, form: { touched, errors, setFieldValue } }) => (
                                            <FormControl fullWidth variant="outlined" size="small">
                                                <TextField
                                                    InputLabelProps={{
                                                        style: { fontWeight: 'bold' },
                                                    }}
                                                    id="cast-category-select"
                                                    select
                                                    size="small"
                                                    label="Cast Category"
                                                    {...field}
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        if (e.target.value === 'Other') {
                                                            setFieldValue('manual_category', '');
                                                        }
                                                    }}
                                                    defaultValue="no"
                                                    error={touched.cast_category && Boolean(errors.cast_category)}
                                                    helperText={touched.cast_category && errors.cast_category}
                                                >
                                                    <MenuItem value="ST">ST</MenuItem>
                                                    <MenuItem value="SC">SC</MenuItem>
                                                    <MenuItem value="OBC">OBC</MenuItem>
                                                    <MenuItem value="EWS">EWS</MenuItem>
                                                    <MenuItem value="General">General</MenuItem>
                                                    <MenuItem value="Other">Other</MenuItem>
                                                </TextField>
                                            </FormControl>
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item xs={12} sm={3} >
                                    <TextField
                                        InputLabelProps={{
                                            style: { fontWeight: 'bold' },
                                        }}
                                        label='Religion'
                                        variant='outlined'
                                        type='text'
                                        size='small'
                                        fullWidth
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.religion}
                                        name='religion'
                                    />
                                </Grid>

                            </Grid>

                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={12} sm={3} >
                                    <TextField
                                        InputLabelProps={{
                                            style: { fontWeight: 'bold' },
                                        }}
                                        label='Father Name'
                                        variant='outlined'
                                        type='text'
                                        size='small'
                                        fullWidth
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.father_name}
                                        name='father_name'
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3} >
                                    <TextField
                                        InputLabelProps={{
                                            style: { fontWeight: 'bold' },
                                        }}
                                        label='Father Occupation'
                                        variant='outlined'
                                        type='text'
                                        size='small'
                                        fullWidth
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.father_occupation}
                                        name='father_occupation'
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3} >
                                    <TextField
                                        InputLabelProps={{
                                            style: { fontWeight: 'bold' },
                                        }}
                                        label='Mother Name'
                                        variant='outlined'
                                        type='text'
                                        size='small'
                                        fullWidth
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.mother_name}
                                        name='mother_name'
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3} >
                                    <TextField
                                        InputLabelProps={{
                                            style: { fontWeight: 'bold' },
                                        }}
                                        label='Mother Occupation'
                                        variant='outlined'
                                        type='text'
                                        size='small'
                                        fullWidth
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.mother_occupation}
                                        name='mother_occupation'

                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={12} sm={3} >
                                    <TextField
                                        InputLabelProps={{
                                            style: { fontWeight: 'bold' },
                                        }}
                                        label='10th School Name'
                                        variant='outlined'
                                        type='text'
                                        size='small'
                                        fullWidth
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.tenth_school_name}
                                        name='tenth_school_name'
                                        error={touched.tenth_school_name && Boolean(errors.tenth_school_name)}
                                        helperText={touched.tenth_school_name && errors.tenth_school_name}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3} >
                                    <TextField
                                        InputLabelProps={{
                                            style: { fontWeight: 'bold' },
                                        }}
                                        label='10th Year of Passing'
                                        variant='outlined'
                                        type='tel'
                                        size='small'
                                        fullWidth
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.tenth_year_passing}
                                        inputProps={{ maxLength: 4 }}
                                        name='tenth_year_passing'
                                        error={touched.tenth_year_passing && Boolean(errors.tenth_year_passing)}
                                        helperText={touched.tenth_year_passing && errors.tenth_year_passing}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3} >
                                    <Field name="tenth_board_name">
                                        {({ field, form: { touched, errors, setFieldValue } }) => (
                                            <FormControl fullWidth variant="outlined" size="small">
                                                <TextField
                                                    InputLabelProps={{
                                                        style: { fontWeight: 'bold' },
                                                    }}
                                                    id="tenth-board-name-select"
                                                    select
                                                    size="small"
                                                    label="10th Board"

                                                    {...field}  
                                                    value={field.value || ''}
                                                    error={touched.tenth_board_name && Boolean(errors.tenth_board_name)}
                                                    helperText={touched.tenth_board_name && errors.tenth_board_name}

                                                >
                                                    <MenuItem value="CBSE - Hindi">CBSE - Hindi</MenuItem>
                                                    <MenuItem value="CBSE - English">CBSE - English</MenuItem>
                                                    <MenuItem value="ICSE - Hindi">ICSE - Hindi</MenuItem>
                                                    <MenuItem value="ICSE - English">ICSE - English</MenuItem>
                                                    <MenuItem value="State Board - Native Language">State Board - Native Language</MenuItem>
                                                    <MenuItem value="State Board - English Language">State Board - English Language</MenuItem>
                                                </TextField>
                                            </FormControl>
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item xs={12} sm={3} >

                                    <TextField
                                        InputLabelProps={{
                                            style: { fontWeight: 'bold' },
                                        }}
                                        label='10 Percentages'
                                        variant='outlined'
                                        type='number'
                                        size='small'
                                        fullWidth
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.tenth_percentage}
                                        name='tenth_percentage'
                                        error={touched.tenth_percentage && Boolean(errors.tenth_percentage)}
                                        helperText={touched.tenth_percentage && errors.tenth_percentage}
                                    />
                                </Grid>

                            </Grid>
                            <Grid container item xs={12} spacing={2} alignItems="center">
                                <Grid item xs={12} sm={3}>
                                    <Box display="flex" alignItems="center">
                                        <Typography variant="body1" component="div" style={{ marginRight: '16px' }}>
                                            <b>Are You Fresher</b>
                                        </Typography>
                                        <Field name="fresher">
                                            {({ field }) => (
                                                <RadioGroup
                                                    {...field}
                                                    aria-labelledby="fresher-buttons-group-label"
                                                    name="fresher"
                                                    row
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        setFresher(e.target.value);
                                                    }}
                                                >
                                                    <FormControlLabel value="Yes" control={<Radio required={true} />} label="Yes" />
                                                    <FormControlLabel value="No" control={<Radio required={true} />} label="No" />
                                                </RadioGroup>
                                            )}
                                        </Field>
                                    </Box>
                                </Grid>
                            </Grid>
                            {fresher === 'Yes' ? (
                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            InputLabelProps={{
                                                style: { fontWeight: 'bold' },
                                            }}
                                            label='How many days you can join'
                                            variant='outlined'
                                            size='small'
                                            type='number'
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.notice_period}
                                            name='notice_period'
                                            error={touched.notice_period && Boolean(errors.notice_period)}
                                            helperText={touched.notice_period && errors.notice_period}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            InputLabelProps={{
                                                style: { fontWeight: 'bold' },
                                            }}
                                            label='Expected CTC in Lakhs'
                                            variant='outlined'
                                            size='small'
                                            type='number'
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.exceptcted_ctc}
                                            name='exceptcted_ctc'
                                            error={touched.exceptcted_ctc && Boolean(errors.exceptcted_ctc)}
                                            helperText={touched.exceptcted_ctc && errors.exceptcted_ctc}
                                        />
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={12} sm={3}>
                                        <TextField
                                            InputLabelProps={{
                                                style: { fontWeight: 'bold' },
                                            }}
                                            label='Notice Period'
                                            variant='outlined'
                                            size='small'
                                            type='number'
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.notice_period}
                                            name='notice_period'
                                            error={touched.notice_period && Boolean(errors.notice_period)}
                                            helperText={touched.notice_period && errors.notice_period}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <TextField
                                            InputLabelProps={{
                                                style: { fontWeight: 'bold' },
                                            }}
                                            label='Current Company Name'
                                            variant='outlined'
                                            size='small'
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.current_company}
                                            name='current_company'
                                            error={touched.current_company && Boolean(errors.current_company)}
                                            helperText={touched.current_company && errors.current_company}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <TextField
                                            InputLabelProps={{
                                                style: { fontWeight: 'bold' },
                                            }}
                                            type='number'
                                            label='Current CTC'
                                            variant='outlined'
                                            size='small'
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.current_ctc}
                                            name='current_ctc'
                                            error={touched.current_ctc && Boolean(errors.current_ctc)}
                                            helperText={touched.current_ctc && errors.current_ctc}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <TextField
                                            InputLabelProps={{
                                                style: { fontWeight: 'bold' },
                                            }}
                                            label='Expected CTC in Lakhs'
                                            variant='outlined'
                                            size='small'
                                            type='number'
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.exceptcted_ctc}
                                            name='exceptcted_ctc'
                                            error={touched.exceptcted_ctc && Boolean(errors.exceptcted_ctc)}
                                            helperText={touched.exceptcted_ctc && errors.exceptcted_ctc}
                                        />
                                    </Grid>

                                </Grid>
                            )}
                            <Grid container item xs={12} spacing={2} alignItems="center">
                                <Grid item xs={12} sm={3}>
                                    <Box display="flex" alignItems="center">
                                        <Typography variant="body1" component="div" style={{ marginRight: '16px' }}>
                                            <b>Diploma</b>
                                        </Typography>
                                        <Field name="diploma">
                                            {({ field }) => (
                                                <RadioGroup
                                                    {...field}
                                                    aria-labelledby="diploma-radio-buttons-group-label"
                                                    name="diploma"
                                                    row
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        setDiploma(e.target.value);
                                                    }}
                                                >
                                                    <FormControlLabel value="Yes" control={<Radio required={true} />} label="Yes" />
                                                    <FormControlLabel value="No" control={<Radio required={true} />} label="No" />
                                                </RadioGroup>
                                            )}
                                        </Field>
                                    </Box>
                                </Grid>
                            </Grid>

                            {diploma === 'Yes' && (
                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={12} sm={3} >
                                        <TextField
                                            InputLabelProps={{
                                                style: { fontWeight: 'bold' },
                                            }}
                                            label='Diploma Course Name'
                                            variant='outlined'
                                            type='text'
                                            size='small'
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.diploma_course_name}
                                            name='diploma_course_name'
                                            error={touched.diploma_course_name && Boolean(errors.diploma_course_name)}
                                            helperText={touched.diploma_course_name && errors.diploma_course_name}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3} >
                                        <TextField
                                            InputLabelProps={{
                                                style: { fontWeight: 'bold' },
                                            }}
                                            label='Diploma University Name'
                                            variant='outlined'
                                            type='text'
                                            size='small'
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.diploma_university_name}
                                            name='diploma_university_name'
                                            error={touched.diploma_university_name && Boolean(errors.diploma_university_name)}
                                            helperText={touched.diploma_university_name && errors.diploma_university_name}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3} >
                                        <TextField
                                            InputLabelProps={{
                                                style: { fontWeight: 'bold' },
                                            }}
                                            label='Diploma College Name'
                                            variant='outlined'
                                            type='text'
                                            size='small'
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.diploma_college_name}
                                            name='diploma_college_name'
                                            error={touched.diploma_college_name && Boolean(errors.diploma_college_name)}
                                            helperText={touched.diploma_college_name && errors.diploma_college_name}

                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={1} >

                                        <TextField
                                            InputLabelProps={{
                                                style: { fontWeight: 'bold' },
                                            }}
                                            label='CGPA/CPI'
                                            variant='outlined'
                                            type='number'
                                            size='small'
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.diploma_percentage}
                                            name='diploma_percentage'
                                            error={touched.diploma_percentage && Boolean(errors.diploma_percentage)}
                                            helperText={touched.diploma_percentage && errors.diploma_percentage}

                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={2} >

                                        <TextField
                                            InputLabelProps={{
                                                style: { fontWeight: 'bold' },
                                            }}
                                            label='Diploma Year of Passing'
                                            variant='outlined'
                                            type='tel'
                                            size='small'
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.diploma_year_of_passing}
                                            inputProps={{ maxLength: 4 }}
                                            name='diploma_year_of_passing'
                                            error={touched.diploma_year_of_passing && Boolean(errors.diploma_year_of_passing)}
                                            helperText={touched.diploma_year_of_passing && errors.diploma_year_of_passing}

                                        />
                                    </Grid>

                                </Grid>
                            )}
                            <Grid container item xs={12} spacing={2} alignItems="center">
                                <Grid item xs={12} sm={3}>
                                    <Box display="flex" alignItems="center">
                                        <Typography variant="body1" component="div" style={{ marginRight: '16px' }}>
                                            <b>12th Standard</b>
                                        </Typography>
                                        <Field name="twelve">
                                            {({ field }) => (
                                                <RadioGroup
                                                    {...field}
                                                    aria-labelledby="twelve-radio-buttons-group-label"
                                                    name="twelve"
                                                    row
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        setTwelve(e.target.value);
                                                    }}
                                                >
                                                    <FormControlLabel value="Yes" control={<Radio required={true} />} label="Yes" />
                                                    <FormControlLabel value="No" control={<Radio required={true} />} label="No" />
                                                </RadioGroup>
                                            )}
                                        </Field>
                                    </Box>
                                </Grid>
                            </Grid>
                            {twelve === 'Yes' && (
                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={12} sm={2} >
                                        <Field name="twelve_course_name">
                                            {({ field, form: { touched, errors, setFieldValue } }) => (
                                                <FormControl fullWidth variant="outlined" size="small">
                                                    <TextField
                                                        InputLabelProps={{
                                                            style: { fontWeight: 'bold' },
                                                        }}
                                                        id="twelve-stream-name-select"
                                                        select
                                                        size="small"
                                                        label="12th Stream"

                                                        {...field}
                                                        value={field.value || ''}
                                                        error={touched.twelve_course_name && Boolean(errors.twelve_course_name)}
                                                        helperText={touched.twelve_course_name && errors.twelve_course_name}


                                                    >
                                                        <MenuItem value="Science">Science</MenuItem>
                                                        <MenuItem value="Commerce">Commerce</MenuItem>
                                                        <MenuItem value="Arts">Arts</MenuItem>

                                                    </TextField>
                                                </FormControl>
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} sm={2} >
                                        <Field name="twelve_board_name">
                                            {({ field, form: { touched, errors, setFieldValue } }) => (
                                                <FormControl fullWidth variant="outlined" size="small">
                                                    <TextField
                                                        InputLabelProps={{
                                                            style: { fontWeight: 'bold' },
                                                        }}
                                                        id="twelve board-board-name-select"
                                                        select
                                                        size="small"
                                                        label="12th Board"

                                                        {...field}
                                                        value={field.value || ''}
                                                        error={touched.twelve_board_name && Boolean(errors.twelve_board_name)}
                                                        helperText={touched.twelve_board_name && errors.twelve_board_name}


                                                    >
                                                        <MenuItem value="CBSE - Hindi">CBSE - Hindi</MenuItem>
                                                        <MenuItem value="CBSE - English">CBSE - English</MenuItem>
                                                        <MenuItem value="ICSE - Hindi">ICSE - Hindi</MenuItem>
                                                        <MenuItem value="ICSE - English">ICSE - English</MenuItem>
                                                        <MenuItem value="State Board - Native Language">State Board - Native Language</MenuItem>
                                                        <MenuItem value="State Board - English Language">State Board - English Language</MenuItem>
                                                    </TextField>
                                                </FormControl>
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} sm={3} >
                                        <TextField
                                            InputLabelProps={{
                                                style: { fontWeight: 'bold' },
                                            }}
                                            label='12th School Name'
                                            variant='outlined'
                                            type='text'
                                            size='small'
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.twelve_school_name}
                                            name='twelve_school_name'
                                            error={touched.twelve_school_name && Boolean(errors.twelve_school_name)}
                                            helperText={touched.twelve_school_name && errors.twelve_school_name}

                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={2} >

                                        <TextField
                                            InputLabelProps={{
                                                style: { fontWeight: 'bold' },
                                            }}
                                            label='12th Percentages'
                                            variant='outlined'
                                            type='number'
                                            size='small'
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.twelve_percentage}
                                            name='twelve_percentage'
                                            error={touched.twelve_percentage && Boolean(errors.twelve_percentage)}
                                            helperText={touched.twelve_percentage && errors.twelve_percentage}

                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3} >

                                        <TextField
                                            InputLabelProps={{
                                                style: { fontWeight: 'bold' },
                                            }}
                                            label='12th Year of Passing'
                                            variant='outlined'
                                            type='tel'
                                            size='small'
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.twelve_year_of_passing_name}
                                            inputProps={{ maxLength: 4 }}
                                            name='twelve_year_of_passing_name'
                                            error={touched.twelve_year_of_passing_name && Boolean(errors.twelve_year_of_passing_name)}
                                            helperText={touched.twelve_year_of_passing_name && errors.twelve_year_of_passing_name}
                                        />
                                    </Grid>

                                </Grid>
                            )}
                            <Grid container item xs={12} spacing={2} alignItems="center">
                                <Grid item xs={12} sm={3}>
                                    <Box display="flex" alignItems="center">
                                        <Typography variant="body1" component="div" style={{ marginRight: '16px' }}>
                                            <b>Degree</b>
                                        </Typography>
                                        <Field name="selected_degree">
                                            {({ field }) => (
                                                <RadioGroup
                                                    {...field}
                                                    aria-labelledby="degree-radio-buttons-group-label"
                                                    name="selected_degree"
                                                    row
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        setDegree(e.target.value);
                                                    }}
                                                >
                                                    <FormControlLabel value="Yes" control={<Radio required={true} />} label="Yes" />
                                                    <FormControlLabel value="No" control={<Radio required={true} />} label="No" />
                                                </RadioGroup>
                                            )}
                                        </Field>
                                    </Box>
                                </Grid>
                                {degree === "Yes" && (
                                    <Grid container item xs={12} spacing={3}>

                                        <Grid item xs={12} sm={3}>
                                            <Field name="degree_type">
                                                {({ field, form: { touched, errors } }) => (
                                                    <FormControl fullWidth variant="outlined" size="small">
                                                        <TextField
                                                            InputLabelProps={{
                                                                style: { fontWeight: 'bold' },
                                                            }}
                                                            id="degree-type-select"
                                                            select
                                                            size="small"
                                                            label="Select Your Degree Type"
                                                            {...field}
                                                            value={field.value || ''}
                                                            error={touched.degree_type && Boolean(errors.degree_type)}
                                                            helperText={touched.degree_type && errors.degree_type}

                                                        >
                                                            <MenuItem value="Degree">Degree</MenuItem>
                                                            <MenuItem value="Unmarried">D2D</MenuItem>
                                                            <MenuItem value="Other">Other</MenuItem>

                                                        </TextField>
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </Grid>

                                        <Grid item xs={12} sm={3} >
                                            <Field name="degree_name">
                                                {({ field, form: { touched, errors, setFieldValue } }) => (
                                                    <FormControl fullWidth variant="outlined" size="small">
                                                        <TextField
                                                            InputLabelProps={{
                                                                style: { fontWeight: 'bold' },
                                                            }}
                                                            id="select--degree-type"
                                                            select
                                                            size="small"
                                                            label="Select Your Degree"

                                                            {...field}
                                                            value={field.value || ''}
                                                            error={touched.degree_name && Boolean(errors.degree_name)}
                                                            helperText={touched.degree_name && errors.degree_name}

                                                        >
                                                            <MenuItem value="B.E.">B.E.</MenuItem>
                                                            <MenuItem value="B.Tech">B.Tech</MenuItem>
                                                            <MenuItem value="Bsc-IT">Bsc-IT</MenuItem>
                                                            <MenuItem value="BBA">BBA</MenuItem>
                                                            <MenuItem value="B.Com">B.Com</MenuItem>
                                                            <MenuItem value="M.E.">M.E.</MenuItem>
                                                            <MenuItem value="MCA">MCA</MenuItem>
                                                            <MenuItem value="M.Com.">M.Com.</MenuItem>
                                                            <MenuItem value="M.Tech.">M.Tech.</MenuItem>
                                                            <MenuItem value="B.Ed.">B.Ed.</MenuItem>
                                                            <MenuItem value="M.Ed.">M.Ed.</MenuItem>
                                                            <MenuItem value="M.A.">M.A.</MenuItem>
                                                            <MenuItem value="B.Arch.">B.Arch.</MenuItem>
                                                            <MenuItem value="B.Sc">B.Sc</MenuItem>
                                                            <MenuItem value="Other">Other</MenuItem>
                                                        </TextField>
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </Grid>

                                        <Grid item xs={12} sm={3} >
                                            <TextField
                                                InputLabelProps={{
                                                    style: { fontWeight: 'bold' },
                                                }}
                                                label='Enter Your Degree Course of Study'
                                                variant='outlined'
                                                type='text'
                                                size='small'
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.degree_course_name}
                                                name='degree_course_name'
                                                error={touched.degree_course_name && Boolean(errors.degree_course_name)}
                                                helperText={touched.degree_course_name && errors.degree_course_name}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3} >
                                            <TextField
                                                InputLabelProps={{
                                                    style: { fontWeight: 'bold' },
                                                }}
                                                label='Degree University Name'
                                                variant='outlined'
                                                type='text'
                                                size='small'
                                                fullWidth
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.degree_uiversity_name}
                                                name='degree_uiversity_name'
                                                error={touched.degree_uiversity_name && Boolean(errors.degree_uiversity_name)}
                                                helperText={touched.degree_uiversity_name && errors.degree_uiversity_name}
                                            />
                                        </Grid>
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item xs={12} sm={4} >
                                                <TextField
                                                    InputLabelProps={{
                                                        style: { fontWeight: 'bold' },
                                                    }}
                                                    label='Degree College Name'
                                                    variant='outlined'
                                                    type='text'
                                                    size='small'
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.degree_college_name}
                                                    name='degree_college_name'
                                                    error={touched.degree_college_name && Boolean(errors.degree_college_name)}
                                                    helperText={touched.degree_college_name && errors.degree_college_name}

                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4} >

                                                <TextField
                                                    InputLabelProps={{
                                                        style: { fontWeight: 'bold' },
                                                    }}
                                                    label='CGPA/CPI'
                                                    variant='outlined'
                                                    type='number'
                                                    size='small'
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.degree_percentage}
                                                    name='degree_percentage'
                                                    error={touched.degree_percentage && Boolean(errors.degree_percentage)}
                                                    helperText={touched.degree_percentage && errors.degree_percentage}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4} >

                                                <TextField
                                                    InputLabelProps={{
                                                        style: { fontWeight: 'bold' },
                                                    }}
                                                    label='Degree Year of Passing'
                                                    variant='outlined'
                                                    type='tel'
                                                    size='small'
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.degree_passing_year}
                                                    inputProps={{ maxLength: 4 }}
                                                    name='degree_passing_year'
                                                    error={touched.degree_passing_year && Boolean(errors.degree_passing_year)}
                                                    helperText={touched.degree_passing_year && errors.degree_passing_year}
                                                />
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid container item xs={12} spacing={2} alignItems="center">
                                <Grid item xs={12}>
                                    <Box display="flex" alignItems="center">
                                        <Typography variant="body1" component="div" style={{ marginRight: '16px' }}>
                                            <b>Are you involved in any pending and / or closed Civil/ Criminal/ case / Proceedings / Charges / enquiry prior to joining</b>
                                        </Typography>
                                        <Field name="criminal_case">
                                            {({ field }) => (
                                                <RadioGroup
                                                    {...field}
                                                    aria-labelledby="criminal-case-group-label"
                                                    name="criminal_case"
                                                    row
                                                >
                                                    <FormControlLabel value="Yes" control={<Radio required={true} />} label="Yes" />
                                                    <FormControlLabel value="No" control={<Radio required={true} />} label="No" />
                                                </RadioGroup>
                                            )}
                                        </Field>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={2} alignItems="center">
                                <Grid item xs={12}>
                                    <Box display="flex" alignItems="center">
                                        <Typography variant="body1" component="div" style={{ marginRight: '16px' }}>
                                            <b>Are you involved in any pending and/ or closed Disciplinary malpractics and / or any other charges / Proceedings/enquiry/case pending against me in any university or any other education authority / institution prior to joining</b>
                                        </Typography>
                                        <Field name="institute_prior">
                                            {({ field }) => (
                                                <RadioGroup
                                                    {...field}
                                                    aria-labelledby="institute-prior-group-label"
                                                    name="institute_prior"
                                                    row
                                                >
                                                    <FormControlLabel value="Yes" control={<Radio required={true} />} label="Yes" />
                                                    <FormControlLabel value="No" control={<Radio required={true} />} label="No" />
                                                </RadioGroup>
                                            )}
                                        </Field>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container item xs={12} spacing={2} alignItems="center">
                                {/* <Container maxWidth="sm" > */}
                                <Grid item xs={12} sm={3}>
                                    <Typography variant="h5"  >
                                        Upload Resume
                                    </Typography>

                                    <Typography variant="subtitle2" align="center" gutterBottom style={{ color: 'red' }}>
                                        (This Uploads Supports only .pdf Files)
                                    </Typography>

                                    <TextField

                                        type="file"
                                        accept=".pdf"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        id="resume"
                                        name="resume"
                                        onChange={handleFileChange}
                                        label="Choose file..."
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>


                            </Grid>
                            <Grid item xs={12} sm={2} alignItems='end'>
                                <Button type='submit' variant='contained' color='primary' fullWidth>
                                    {isSubmitting ? 'Submitting...' : 'Submit application'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                )}

            </Formik>

        </Box>
    );
};

export default ApplyForm;
