import * as React from 'react';
import { Box, IconButton, MenuItem, Select, useTheme, Button, Dialog, DialogTitle, DialogContent, Typography, Grid, Paper } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import BookmarkAddedOutlinedIcon from '@mui/icons-material/BookmarkAddedOutlined';
import axios from 'axios';
import config from '../../config';
import { tokens } from '../../theme';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useState } from "react";
import CandidateDetails from '../candidateDetails';

const HOST_ADDRESS = config.apiUrl;


const Candidate = () => {
    const [candidateList, setCandidateList] = React.useState([]);
    const [selectedStatusMap, setSelectedStatusMap] = React.useState({});
    const [detailsDialogOpen, setDetailsDialogOpen] = React.useState(false);
    const [candidateData, setCandidateData] = useState()
    const theme = useTheme();
    const colors = tokens(theme.palette.mode)

    React.useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const url = `${HOST_ADDRESS}/applicationForm/all`;

            const response = await axios.get(url);
            setCandidateList(response.data.candidate);

            const initialStatusMap = {};
            response.data.candidate.forEach(candidate => {
                initialStatusMap[candidate.id] = 'Application Reviewed';
            });
            setSelectedStatusMap(initialStatusMap);
        } catch (error) {
            console.error('Error fetching candidate data:', error);
        }
    };

    const changeStatus = async (candidateId, newStatus) => {
        try {
            const statusData = { candidate_id: candidateId, new_status: newStatus };
            const statusUrl = HOST_ADDRESS + '/applicationForm/status';
            await axios.post(statusUrl, statusData, { headers: { 'Content-Type': 'application/json' } });

            setSelectedStatusMap(prevMap => ({
                ...prevMap,
                [candidateId]: newStatus,
            }));
        } catch (error) {
            console.error('Error changing status:', error);
        }
    };

    const handleSave = async (candidateId) => {
        const selectedStatus = selectedStatusMap[candidateId];
        if (selectedStatus && candidateId) {
            await changeStatus(candidateId, selectedStatus);
            await fetchData();
        } else {
            console.error('Invalid selection.');
        }
    };



    const columns = [
        { field: 'id', headerName: 'ID', id: 'row_id' },
        { field: 'job_name', headerName: 'Applied for', flex: 1, id: 'job_name' },
        {
            headerName: 'Candidate Name',
            flex: 1,
            id: 'candidate_name',
            renderCell: (params) => (
                <Box key={params.row.id}>{params.row.first_name} {params.row.last_name}</Box>
            )
        },
        {
            field: 'gender',
            headerName: 'Gender',
            flex: 1,
            id: 'gender',
            renderCell: (params) => (
                <Box key={params.row.id}>{params.row.gender.charAt(0).toUpperCase() + params.row.gender.slice(1)}</Box>
            )
        },
        {
            field: 'time',
            headerName: 'Date & Time',
            id: 'time',
            flex: 1,
            renderCell: (params) => (
                <Box key={params.row.id}>{params.row.time.split('.')[0]}</Box>
            )
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            id: 'status',
            renderCell: (params) => (
                <Box key={params.row.id}>{params.row.status.charAt(0).toUpperCase() + params.row.status.slice(1)}</Box>
            )
        },
        {
            field: 'show_details',
            headerName: 'Show Details',
            flex: 1,
            id: "show_details",
            renderCell: ({ row }) => (
                <Box key={row.id}>
                    <Button onClick={() => {
                        setCandidateData(row)
                        setDetailsDialogOpen(true)
                    }}>
                        Show Details
                    </Button>
                </Box>
            )
        },
        {
            field: 'actions',
            headerName: 'Change Status',
            flex: 1.5,
            id: "change_status",
            renderCell: (params) => (
                <Box key={params.row.id} sx={{ display: 'flex', mt: '5px', alignContent: 'center' }} >
                    <Select
                        value={selectedStatusMap[params.row.id] || ''}
                        variant="outlined"
                        size='small'
                        onChange={(event) => {
                            const candidateId = params.row.id;
                            const newStatus = event.target.value;
                            setSelectedStatusMap(prevMap => ({
                                ...prevMap,
                                [candidateId]: newStatus,
                            }));
                        }}
                    >
                        <MenuItem value="Application Reviewed">Application Reviewed</MenuItem>
                        <MenuItem value="Screening">Screening</MenuItem>
                        <MenuItem value="Screening Completed">Screening Completed</MenuItem>
                        <MenuItem value="Interview Scheduled">Interview Scheduled</MenuItem>
                        <MenuItem value="Initial Interview">Initial Interview</MenuItem>
                        <MenuItem value="Technical Interview">Technical Interview</MenuItem>
                        <MenuItem value="Cultural Fit Interview">Cultural Fit Interview</MenuItem>
                        <MenuItem value="Offer on Hold">Offer on Hold</MenuItem>
                        <MenuItem value="Offer Extended">Offer Extended</MenuItem>
                        <MenuItem value="Offer Accepted">Offer Accepted</MenuItem>
                        <MenuItem value="Offer Declined">Offer Declined</MenuItem>
                        <MenuItem value="Onboarding">Onboarding</MenuItem>
                        <MenuItem value="Rejected">Rejected</MenuItem>
                        <MenuItem value="Withdrawn">Withdrawn</MenuItem>
                        <MenuItem value="OnHold">OnHold</MenuItem>
                        <MenuItem value="Not Joined">Not Joined</MenuItem>
                    </Select>
                    <IconButton onClick={() => handleSave(params.row.id)}>
                        <BookmarkAddedOutlinedIcon />
                    </IconButton>
                </Box>
            ),
        },
    ];

    return (
        <Box m={3} sx={{ width: '96%' }}>
            <Paper elevation={3} sx={{ padding: 3 }}>
                <Typography variant="h2" sx={{ marginBottom: '20px' }}>
                    Candidate Details
                </Typography>
                <Box height="70vh" sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none"
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300]
                    },
                    "& .MuiDataGrid-columnHeader": {
                        backgroundColor: colors.primary[800],
                        borderBottom: "none"
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400]
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.primary[800]
                    },
                }}>
                    <DataGrid
                        rows={candidateList}
                        columns={columns}
                        components={{ Toolbar: GridToolbar }}
                        disableSelectionOnClick
                        getRowId={(row) => row.id}

                        
                    />
                </Box>
                <Dialog
                    maxWidth="xl"
                    open={detailsDialogOpen}
                >
                    <DialogTitle sx={{ mb: "10px", p: 3, alignContent: "end" }}>
                        <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item >
                                <Typography variant="h2" sx={{ marginTop: '10px', marginLeft: '20px' }}>
                                    Candidate Details
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button onClick={() => setDetailsDialogOpen(false)}>
                                    <CloseOutlinedIcon />
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <Box sx={{ marginTop: '-60px' }}>
                        <DialogContent>
                            <CandidateDetails candidataDataJson={candidateData} />
                        </DialogContent>
                    </Box>
                </Dialog>
            </Paper>
        </Box>
    );
};

export default Candidate;
