import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from "@mui/material";
import SideMenu from "./pages/global/Sidebar";
import Topbar from "./pages/global/Topbar";
import { ColorModeContext, useMode } from "./theme";
import Dashboard from "./pages/dashboard/dashboard";
import JobAdd from './pages/jobAdd/JobAdd';
import Login from "./pages/login/login";
import Skills from "./pages/skills/skill";
import Candidate from './pages/candidate/candidate';
import Jobs from './pages/jobs/jobs';
import ApplyForm from './pages/apply/applyForm';

function App() {
  const [theme, colorMode] = useMode();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [disableSideMenuTopbar, setDisableSideMenuTopbar] = useState(false);

  const checkUserToken = () => {
    const userToken = localStorage.getItem('ats-token');
    setIsLoggedIn(!!userToken && userToken !== 'undefined');
  };

  useEffect(() => {
    checkUserToken();
    if (location.pathname === "/apply" || location.pathname === "/applyForm" || (isLoggedIn && location.pathname === "/jobs")) {
      setDisableSideMenuTopbar(true);
    } else {
      setDisableSideMenuTopbar(false);
    }
  }, [location, isLoggedIn]);

  const checkApplyParams = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.has('index') && searchParams.has('applyfor');
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {!disableSideMenuTopbar && isLoggedIn && <SideMenu />}
          <main className="content">
            {!disableSideMenuTopbar && isLoggedIn && <Topbar />}
            <Routes>
              <Route path="/dashboard" element={isLoggedIn ? <Dashboard /> : <Navigate to="/login" />} />
              <Route path="/jobadd" element={isLoggedIn ? <JobAdd /> : <Navigate to="/login" />} />
              <Route path="/skills" element={isLoggedIn ? <Skills /> : <Navigate to="/login" />} />
              <Route path="/candidate" element={isLoggedIn ? <Candidate /> : <Navigate to="/login" />} />
              <Route path="/login" element={isLoggedIn ? <Navigate to="/dashboard" /> : <Login />} />
              <Route path="/apply" element={<Jobs />} />
              <Route path="/applyForm" element={checkApplyParams() ? <ApplyForm /> : <Navigate to="/apply" />} />
              <Route path="/*" element={<Navigate to={isLoggedIn ? "/" : "/apply"} />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
