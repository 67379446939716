import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Box, IconButton, Typography, useTheme, Button } from '@mui/material';
import { Link, useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import "react-pro-sidebar/dist/css/styles.css";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';

import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';

const Item = ({ title, to, icon, selected, setSelected }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <MenuItem
            active={selected === title}
            style={{ color: colors.grey[100] }}
            onClick={() => setSelected(title)}
            icon={icon}
        >
            <Typography>{title}</Typography>
            <Link to={to} />
        </MenuItem>
    )
}

const SideMenu = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const user_email = localStorage.getItem('ats-token')
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [selected, setSelected] = useState("Dashboard");
    const [signingOut, setSigningOut] = useState(false);
    const navigate = useNavigate();

    const logout = () => {
        setSigningOut(true);
        localStorage.removeItem('ats-token');
        navigate('/');
        window.location.reload();
        setTimeout(() => {
            
            setSigningOut(false);
        }, 2000);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                "& .pro-sidebar-inner": {
                    background: `${colors.primary[800]} !important`,
                },
                "& .pro-icon-wrapper": {
                    backgroundColor: `transparent !important`,
                },
                "& .pro-inner-item": {
                    padding: "5px 35px 5px 20px !important",
                },
                "& .pro-menu-item.hover": {
                    color: "#ed0f07 !important",
                },
                "& .pro-menu-item.active": {
                    color: "#ed0f07 !important",
                },
            }}
        >
            <ProSidebar collapsed={isCollapsed}>
                <Menu iconShape="square">
                    <MenuItem
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        icon={isCollapsed ? <MenuTwoToneIcon /> : undefined}
                        style={{
                            margin: "10px 0 20px 0",
                            color: colors.grey[100],
                        }}
                    >
                        {!isCollapsed && (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                ml="15px"
                            >
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <img alt="profile-user"
                                        width="140px"
                                        height="140px"
                                        src={`../../assets/pipluspi_logo.svg`}
                                    />
                                </Box>
                                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                                    <MenuTwoToneIcon />
                                </IconButton>
                            </Box>
                        )}
                    </MenuItem>

                    {!isCollapsed && (
                        <Box mb="25px">
                            <Box textAlign="center">
                                <Typography
                                    variant="h5"
                                    color={colors.grey[100]}
                                    fontWeight="bold"
                                    sx={{ m: "10px 0 0 0" }}
                                >{user_email}</Typography>
                            </Box>
                        </Box>
                    )}

                    <Box paddingLeft={isCollapsed ? undefined : '10%'}>
                        <Item title="Dashboard"
                            to="/dashboard"
                            icon={<HomeOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Typography
                            variant="h6"
                            color='black'
                            sx={{ m: "15px 0 5px 20px" }}
                        ><b>JOB</b></Typography>
                        <Item title="Add Skills"
                            to="/skills"
                            icon={<ContactsOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item title="Add Job"
                            to="/jobadd"
                            icon={<WorkOutlineOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{ m: "15px 0 5px 20px" }}
                        ><b>CANDIDATES</b></Typography>

                        <Item title="Candidates Details"
                            to="/candidate"
                            icon={<PersonOutlineOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                       
                    </Box>
                </Menu>
            </ProSidebar>
            <Box
                sx={{
                    mt: 'auto', 
                    mb: 2, 
                    textAlign: 'center',
                    padding: '0 20px',
                    backgroundColor: colors.primary[800],
                    "@media (max-width: 600px)": {
                        padding: '0 10px',

                    }
                    
                }}
            >
                <Button
                    variant="contained"
                    color="error"
                    style={{ 
                        margin: "10px 0", 
                        padding: "12px 20px",
                        
                    }}
                    startIcon={<ExitToAppOutlinedIcon />}
                    onClick={logout}
                    fullWidth
                >
                    {signingOut ? 'Signing Out...' : (!isCollapsed && 'Sign Out')}
                </Button>
            </Box>
        </Box>
    )
};

export default SideMenu;
