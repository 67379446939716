import React from "react";
import { Typography, Divider, Grid, Paper, Button, Box } from "@mui/material";
import config from '../../config';
const HOST_ADDRESS = config.apiUrl;

const CandidateDetails = ({ candidataDataJson }) => {
    const {
        first_name,
        middle_name,
        last_name,
        email_id,
        contact_number,
        alternate_contact,
        address,
        city_name,
        state_name,
        zip_code,
        gender,
        marital_status,
        religion,
        cast_category,
        father_name,
        father_occupation,
        mother_name,
        mother_occupation,
        tenth_school_name,
        tenth_year_passing,
        tenth_percentage,
        tenth_board_name,
        diploma,
        diploma_course_name,
        diploma_university_name,
        diploma_college_name,
        diploma_year_of_passing,
        diploma_percentage,
        twelve,
        twelve_course_name,
        twelve_board_name,
        twelve_school_name,
        twelve_year_of_passing_name,
        twelve_percentage,
        selected_degree,
        degree_name,
        degree_type,
        degree_course_name,
        degree_uiversity_name,
        degree_college_name,
        degree_passing_year,
        degree_percentage,
        criminal_case,
        institute_prior,
        six_month_apply,
        job_name,
        time,
        language,
        fresher,
        current_ctc,
        current_company,
        Notice_period,
        exceptcted_ctc,
        resume_name


    } = candidataDataJson;
    const timePartOftime = time.split(' ')[0]
    const showResume = async (resume_name) => {


        const url = HOST_ADDRESS + '/applicationForm/pdf';
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ resumeName: resume_name }),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const pdfData = await response.arrayBuffer();
            displayPDF(pdfData);
        } catch (error) {
            console.error('Error fetching resume:', error);
        }
    }
    const displayPDF = (pdfData) => {
        const blob = new Blob([pdfData], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        window.open(url);

    };
    return (
        <Paper sx={{ p: 3, borderRadius: 2, bgcolor: "background.paper" }}>
            
            <Grid container alignItems="center" justifyContent="flex-end">
                <Grid item>
                    <Box>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ width: '100%' }}
                            onClick={() => {
                            showResume(resume_name);
                            }}
                        >
                            Show Resume
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Divider sx={{ mt: 3, mb: 2 }} />
            <Grid container spacing={3}><Grid item >
                <Typography variant="h5" sx={{ mt: 3, mb: 2 }}>Applied For : <b>{(job_name?.toUpperCase() || '').toUpperCase()}</b></Typography>
            </Grid>
                <Grid item >
                    <Typography variant="h5" sx={{ mt: 3, mb: 2 }}>Applied Date : <b>{(timePartOftime?.toUpperCase() || '').toUpperCase()}</b></Typography>
                </Grid></Grid>
            <Grid container spacing={3}>
                <Grid item >
                    <Typography variant="h3" sx={{ mt: 3, mb: 2 }}><b>Personal Details</b></Typography>
                </Grid>

            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h5" color="textSecondary">
                        First Name: <b>{(first_name?.toUpperCase() || '').toUpperCase()}</b>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h5" color="textSecondary">
                        Middle Name: <b>{(middle_name?.toUpperCase() || '').toUpperCase()}</b>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h5" color="textSecondary">
                        Last Name: <b>{(last_name?.toUpperCase() || '').toUpperCase()}</b>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h5" color="textSecondary">
                        Gender: <b>{(gender?.toUpperCase() || '').toUpperCase()}</b>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h5" color="textSecondary">
                        Merital Status: <b>{(marital_status?.toUpperCase() || '').toUpperCase()}</b>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h5" color="textSecondary">
                        Religion: <b>{(religion?.toUpperCase() || '').toUpperCase()}</b>
                        
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h5" color="textSecondary">
                        Cast: <b>{(cast_category?.toUpperCase() || '').toUpperCase()}</b>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h5" color="textSecondary">
                        Applied in Last Six Month: <b>{(six_month_apply?.toUpperCase() || '').toUpperCase()}</b>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h5" color="textSecondary">
                        Proficient Language : <b>{(language?.toUpperCase() || '').toUpperCase()}</b>
                    </Typography>
                </Grid>

            </Grid>

            <Typography variant="h3" sx={{ mt: 3, mb: 2 }}><b>Contact Information</b></Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h5" color="textSecondary">
                        Email Id: <b>{(email_id?.toUpperCase() || '')}</b>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h5" color="textSecondary">
                        Phone Number: <b>{(contact_number?.toUpperCase() || '').toUpperCase()}</b>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h5" color="textSecondary">
                        Alternate Contact: <b>{(alternate_contact?.toUpperCase() || '').toUpperCase()}</b>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h5" color="textSecondary">
                        Address: <b>{(address?.toUpperCase() || '').toUpperCase()}</b>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h5" color="textSecondary">
                        City: <b>{(city_name?.toUpperCase() || '').toUpperCase()}</b>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h5" color="textSecondary">
                        State: <b>{(state_name?.toUpperCase() || '').toUpperCase()}</b>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h5" color="textSecondary">
                        Zip-Code: <b>{(zip_code?.toUpperCase() || '').toUpperCase()}</b>
                    </Typography>
                </Grid>
            </Grid>

            <Typography variant="h3" sx={{ mt: 3, mb: 2 }}><b>Family Details</b></Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h5" color="textSecondary">
                        Father Name: <b>{(father_name?.toUpperCase() || '').toUpperCase()}</b>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h5" color="textSecondary">
                        Father Occupation: <b>{(father_occupation?.toUpperCase() || '').toUpperCase()}</b>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h5" color="textSecondary">
                        Mother Name: <b>{(mother_name?.toUpperCase() || '').toUpperCase()}</b>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h5" color="textSecondary">
                        Mother Occupation: <b>{(mother_occupation?.toUpperCase() || '').toUpperCase()}</b>
                    </Typography>
                </Grid>

            </Grid>
            <Typography variant="h3" sx={{ mt: 3, mb: 2 }}><b>10th Standard</b></Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h5" color="textSecondary">
                        School Name: <b>{(tenth_school_name?.toUpperCase() || '').toUpperCase()}</b>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h5" color="textSecondary">
                        Passing Year: <b>{(tenth_year_passing?.toUpperCase() || '').toUpperCase()}</b>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h5" color="textSecondary">
                        Percentages: <b>{(tenth_percentage?.toUpperCase() || '').toUpperCase()}</b>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h5" color="textSecondary">
                        Board Name: <b>{(tenth_board_name?.toUpperCase() || '').toUpperCase()}</b>
                    </Typography>
                </Grid>
            </Grid>

            {diploma === 'Yes' && (
                <>
                    <Typography variant="h3" sx={{ mt: 3, mb: 2 }}><b>Diploma</b></Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="h5" color="textSecondary">
                                Diploma: <b>{(diploma_course_name?.toUpperCase() || '').toUpperCase()}</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="h5" color="textSecondary">
                                University Name: <b>{(diploma_university_name?.toUpperCase() || '').toUpperCase()}</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="h5" color="textSecondary">
                                College Name: <b>{(diploma_college_name?.toUpperCase() || '').toUpperCase()}</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="h5" color="textSecondary">
                                Passing Year: <b>{(diploma_year_of_passing?.toUpperCase() || '').toUpperCase()}</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="h5" color="textSecondary">
                                SPI or CGPA: <b>{(diploma_percentage?.toUpperCase() || '').toUpperCase()}</b>
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            )}
            {twelve === 'Yes' && (
                <>
                    <Typography variant="h3" sx={{ mt: 3, mb: 2 }}><b>12th Standard</b></Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="h5" color="textSecondary">
                                Stream: <b>{(twelve_course_name?.toUpperCase() || '').toUpperCase()}</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="h5" color="textSecondary">
                                Board Name: <b>{(twelve_board_name?.toUpperCase() || '').toUpperCase()}</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="h5" color="textSecondary">
                                School Name: <b>{(twelve_school_name?.toUpperCase() || '').toUpperCase()}</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="h5" color="textSecondary">
                                Passing Year: <b>{(twelve_year_of_passing_name?.toUpperCase() || '').toUpperCase()}</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="h5" color="textSecondary">
                                Percentage: <b>{(twelve_percentage?.toUpperCase() || '').toUpperCase()}</b>
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            )}
            {selected_degree === 'Yes' && (
                <>
                    <Typography variant="h3" sx={{ mt: 3, mb: 2 }}><b>Degree</b></Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="h5" color="textSecondary">
                                Degree Name: <b>{(degree_name?.toUpperCase() || '').toUpperCase()}</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="h5" color="textSecondary">
                                Degree Type: <b>{(degree_type?.toUpperCase() || '').toUpperCase()}</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="h5" color="textSecondary">
                                Degree Course: <b>{(degree_course_name?.toUpperCase() || '').toUpperCase()}</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="h5" color="textSecondary">
                                University Name: <b>{(degree_uiversity_name?.toUpperCase() || '').toUpperCase()}</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="h5" color="textSecondary">
                                College Name: <b>{(degree_college_name?.toUpperCase() || '').toUpperCase()}</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="h5" color="textSecondary">
                                Passing Year: <b>{(degree_passing_year?.toUpperCase() || '').toUpperCase()}</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="h5" color="textSecondary">
                                SPI Or CGPA: <b>{(degree_percentage?.toUpperCase() || '').toUpperCase()}</b>
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            )}
            {selected_degree === 'No' ? (
                <>
                    <Typography variant="h3" sx={{ mt: 3, mb: 2 }}><b>Fresher</b></Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="h5" color="textSecondary">
                                Fresher: <b>{(fresher?.toUpperCase() || '').toUpperCase()}</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="h5" color="textSecondary">
                                Current CTC: <b>{(current_ctc?.toUpperCase() || '').toUpperCase()}</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="h5" color="textSecondary">
                                Current Company: <b>{(current_company?.toUpperCase() || '').toUpperCase()}</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="h5" color="textSecondary">
                                Notice Period: <b>{(Notice_period?.toUpperCase() || '').toUpperCase()}</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="h5" color="textSecondary">
                                Expected CTC: <b>{(exceptcted_ctc?.toUpperCase() || '').toUpperCase()}</b>
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <>
                    <Typography variant="h3" sx={{ mt: 3, mb: 2 }}><b>Fresher</b></Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="h5" color="textSecondary">
                                Expected CTC: <b>{(exceptcted_ctc?.toUpperCase() || '').toUpperCase()}</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="h5" color="textSecondary">
                                Joining Days: <b>{(Notice_period?.toUpperCase() || '').toUpperCase()}</b>
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            )}
            <Typography variant="h3" sx={{ mt: 3, mb: 2 }}><b>Criminal Cases Or Any Bond</b></Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h5" color="textSecondary">
                        Criminal Cases: <b>{(criminal_case?.toUpperCase() || '').toUpperCase()}</b>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h5" color="textSecondary">
                        Any bond or Institute Prior: <b>{(institute_prior?.toUpperCase() || '').toUpperCase()}</b>
                    </Typography>
                </Grid>

            </Grid>

        </Paper>
    );
};

export default CandidateDetails;
