import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Paper } from "@mui/material";
import Header from "../components/Header";
import config from '../../config';
import axios from 'axios';

const HOST_ADDRESS = config.apiUrl;

const Dashboard = () => {
    const [toatalJobs, setToatalJobs] = useState(0);
    const [activeJobs, setActiveJobs] = useState(0);
    const [interviewScheduled, setInterviewScheduled] = useState(0);
    const [totalCandidates, setTotalCandidates] = useState(0);
    const [pendingCandidates, setPendingCandidates] = useState(0);
    const [rejectedCandidates, setRejectedCandidates] = useState(0);
    const [confirmationMail, setConfirmationMail] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${HOST_ADDRESS}/dashboard/all`);
                const { active_job, total_job, interview_scheduled, total_candidate, pending_candidate, rejected_candidate, confirmation_mail } = response.data;

                setToatalJobs(total_job);
                setActiveJobs(active_job);
                setInterviewScheduled(interview_scheduled);
                setTotalCandidates(total_candidate);
                setPendingCandidates(pending_candidate);
                setRejectedCandidates(rejected_candidate);
                setConfirmationMail(confirmation_mail);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <Box m="20px">
            <Header title="DASHBOARD" />
            <Grid container spacing={3} marginTop='15px'>
                <Typography variant='h3' fontWeight="bold" marginLeft='20px' marginTop='10px'>Job Details</Typography>
                <Grid container spacing={3} marginLeft='15px' marginTop='1px'>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper elevation={3}>
                            <Box p={2}>
                                <Typography variant="h6">Total Jobs</Typography>
                                <Typography variant="h4">{toatalJobs || 0}</Typography>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper elevation={3}>
                            <Box p={2}>
                                <Typography variant="h6">Active Jobs</Typography>
                                <Typography variant="h4">{activeJobs || 0}</Typography>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
                <Typography variant='h3' fontWeight="bold" marginLeft='20px' marginTop='30px'>Candidate Details</Typography>
                <Grid container spacing={3} marginLeft='15px' marginTop='1px'>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper elevation={3}>
                            <Box p={2}>
                                <Typography variant="h6">Total Candidates</Typography>
                                <Typography variant="h4">{totalCandidates || 0}</Typography>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper elevation={3}>
                            <Box p={2}>
                                <Typography variant="h6">Interview Scheduled</Typography>
                                <Typography variant="h4">{interviewScheduled || 0}</Typography>
                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <Paper elevation={3}>
                            <Box p={2}>
                                <Typography variant="h6">Review Pending</Typography>
                                <Typography variant="h4">{pendingCandidates || 0}</Typography>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper elevation={3}>
                            <Box p={2}>
                                <Typography variant="h6">Rejected Candidates</Typography>
                                <Typography variant="h4">{rejectedCandidates || 0}</Typography>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper elevation={3}>
                            <Box p={2}>
                                <Typography variant="h6">Confirmation Mail Sented</Typography>
                                <Typography variant="h4">{confirmationMail || 0}</Typography>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
                
            </Grid>
        </Box>

    );
};

export default Dashboard;
