import { Box, IconButton, Typography } from "@mui/material";


import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SettingsIcon from '@mui/icons-material/Settings';



const Topbar = () => {

    
    

    return <Box display="flex" justifyContent="space-between" p={2}>
        <Box display="flex" borderRadius="3px">
            <Typography variant="h4">PIPLUSPI JOB PORTAL</Typography>
        </Box>
        <Box display="flex">
            <IconButton >
                <NotificationsOutlinedIcon />
            </IconButton>
            <IconButton>
                <SettingsIcon />
            </IconButton>
            <IconButton>
                <PersonOutlineOutlinedIcon />
            </IconButton>
        </Box>
    </Box>;
};

export default Topbar