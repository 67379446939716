import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import * as yup from "yup";
import axios from 'axios';
import { Formik } from "formik";
import { tokens } from "../../theme";
import config from "../../config"
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

const HOST_ADDRESS = config.apiUrl;

const userSchema = yup.object().shape({
  email: yup.string().email("Invalid email format").required("Email is required"),
  password: yup.string().required("Password is required"),
});

const Login = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShow) => !prevShow);
  };
  useEffect(() => {
    sessionStorage.removeItem('ats-token');
    return () => { };
  }, []);

  const submitLoginForm = async (values, { setSubmitting }) => {
    try {
      await axios.post(HOST_ADDRESS + '/login/login', values);
      localStorage.setItem('ats-token', values.email);
      navigate('/dashboard');
      
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setErrorMessage('Incorrect email or password.');
      } else {
        console.error(error);
      }
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Box
        border={2}
        borderColor={colors.redAccent[600]}
        maxWidth={450}
        width="100%"
        padding={4}
      >
        <Typography variant="h3" align="center" gutterBottom>
          <span style={{ color: colors.redAccent[500] }}>PIPLUSPI</span> Job Portal
        </Typography>

        <Formik
          onSubmit={submitLoginForm}
          initialValues={{ email: '', password: '' }}
          validationSchema={userSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Email Address"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && errors.email}
                helperText={touched.email && errors.email}
                margin="normal"
              />
              <TextField
                fullWidth
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                label="Password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && errors.password}
                helperText={touched.password && errors.password}
                margin="normal"
                InputProps={{
                  endAdornment:(
                    <Button onClick={togglePasswordVisibility}aria-label={showPassword ? 'Hide password' : 'Show password'}>
                      {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                    </Button>
                  )
                }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                style={{ marginTop: 20 }}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Logging in...' : 'Login'}
              </Button>
            </form>
          )}
        </Formik>
        {errorMessage && (
          <Typography variant="body2" color="error" align="center" marginTop='5px'>
            {errorMessage}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default Login;
