import React, { useState, useEffect } from 'react';
import { TextField, Typography, Button, Box, Grid, Container, Paper } from "@mui/material";
import { Formik } from "formik";
import axios from 'axios';
import * as yup from "yup";
import config from '../../config';

const HOST_ADDRESS = config.apiUrl;

const initialValues = {
  skill: '',
  description: '',
};

const skillSchema = yup.object().shape({
  skill: yup.string().required("Skill title is required"),
});

const Skills = () => {
  const [skillList, setSkillList] = useState([]);
  useEffect(() => {
    const url_all = HOST_ADDRESS + '/skill/all';
    axios.get(url_all).then((repos) => {
      setSkillList(repos.data.skills);
    });
  }, []);

  const refreshSkillList = () => {
    const url_all = HOST_ADDRESS + '/skill/all';
    axios.get(url_all).then((repos) => {
      setSkillList(repos.data.skills);
    });
  };

  const submit = (values, { resetForm }) => {
    const url = HOST_ADDRESS + '/skill/add';
    axios.post(url, values, {
      headers: {
        "content-type": "application/json"
      }
    }).then(() => {
      refreshSkillList();
      resetForm();
    }).catch(error => {
      console.error('Error adding skill:', error);
    });
  };

  const deleteSkill = (id) => {
    const confirmed = window.confirm('Are you sure you want to delete this skill?');

    if (!confirmed) {
      return;
    }

    axios.delete(`${HOST_ADDRESS}/skill/remove/${id}`)
      .then(() => {
        setSkillList(skillList.filter(skill => skill.id !== id));
      })
      .catch(error => {
        console.error('Error deleting skill:', error);
      });
  };

  return (
    <Container>
      <Box m={2}>
        <Paper elevation={3} sx={{ padding: 3 }}>
          <Typography variant="h3" gutterBottom>Add Skills</Typography>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Formik
              initialValues={initialValues}
              validationSchema={skillSchema}
              onSubmit={(values, { resetForm }) => {
                submit(values, { resetForm });
              }}
            >
              {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        size="small"
                        label="Skill"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.skill}
                        name="skill"
                        error={touched.skill && Boolean(errors.skill)}
                        helperText={touched.skill && errors.skill}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        size="small"
                        label="Description"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.description}
                        name="description"
                        error={touched.description && Boolean(errors.description)}
                        helperText={touched.description && errors.description}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button type="submit" variant="contained" color="primary" fullWidth>Add Skill</Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Paper>
          <Box mt={4}>
            <Typography variant="h4" gutterBottom>Skill List</Typography>
            <Box
              sx={{
                height: '400px', 
                overflowY: 'auto',
              }}
            >
              <Grid container spacing={2}>
                {skillList.map(skill => (
                  <Grid item xs={12} sm={6} md={4} key={skill.id}>
                    <Paper elevation={1} sx={{ padding: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Box>
                        <Typography variant="body2">{skill.name}</Typography>
                      </Box>
                      <Button variant="contained" color="secondary" onClick={() => deleteSkill(skill.id)} sx={{ backgroundColor: 'black', color: 'white' }}>Delete</Button>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default Skills;
