import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from 'axios';
import { Box, Button, TextField, Typography, MenuItem, useTheme, InputLabel, Select, FormControl, ListItem, ListItemText, IconButton, Paper } from '@mui/material';
import config from '../../config';
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from '@mui/material/useMediaQuery';
import { tokens } from '../../theme';
import DeleteIcon from '@mui/icons-material/Delete';
import BookmarkAddedOutlinedIcon from '@mui/icons-material/BookmarkAddedOutlined';
const HOST_ADDRESS = config.apiUrl;

const initialValues = {
    title: '',
    responsibility: '',
    description: '',
    no_of_vacancy: '',
    salary_min: '',
    salary_max: '',
    exp_min: '',
    exp_max: '',
    job_expiry: ''
}
const jobSchema = yup.object().shape({
    title: yup.string().required("Title is required"),
    description: yup.string(),
    no_of_vacancy: yup.number().required("No of Vaccancy is required"),
    salary_min: yup.number().required("Minimum Salary is required"),
    salary_max: yup.number().required("Maximum Salary is required"),
    exp_min: yup.number().required("Minimum Experiece is required"),
    exp_max: yup.number().required("Maximum Experiece is required"),
    job_expiry: yup.number().required("Job Expiry is required")

})
const JobAdd = () => {
    const [jobList, setJobList] = useState([]);
    const [skillList, setSkillList] = useState([]);
    const [selectedSkill, setSelectedSkill] = useState('');
    const [addedSkills, setAddedSkills] = useState([]);
    const [selectedStatusMap, setSelectedStatusMap] = React.useState({});
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const theme = useTheme()
    const colors = tokens(theme.palette.mode);

    useEffect(() => {

        const fetchJob = async () => {
            try {
                const response = await axios.get(`${HOST_ADDRESS}/job/list`);
                setJobList(response.data.jobs);
                const skillResponse = await axios.get(`${HOST_ADDRESS}/skill/all`);
                setSkillList(skillResponse.data.skills);

                const initialStatusMap = {};
                response.data.jobs.forEach(jobs => {
                    initialStatusMap[jobs.id] = 'no';

                });
                setSelectedStatusMap(initialStatusMap)
            } catch (error) {
                console.error('Error fetching jobs:', error);
            }
        };

        fetchJob();
    }, []);


    const addSkill = () => {
        if (selectedSkill && !addedSkills.includes(selectedSkill)) {
            setAddedSkills([...addedSkills, selectedSkill]);
            setSelectedSkill('');
        }
    };

    const handleRemoveSkill = (index) => {
        const updatedSkills = [...addedSkills];
        updatedSkills.splice(index, 1);
        setAddedSkills(updatedSkills);
    };

    const submit = async (values, { resetForm }) => {
        try {
            const jobWithSkills = { ...values, skills: addedSkills };
            await axios.post(`${HOST_ADDRESS}/job/add`, jobWithSkills, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            refreshJobList();
            setAddedSkills([]);
            resetForm({});
        } catch (error) {
            console.error('Error adding job:', error);
        }
    };

    const refreshJobList = async () => {
        try {
            const response = await axios.get(`${HOST_ADDRESS}/job/list`);
            setJobList(response.data.jobs);
            const initialStatusMap = {};
            response.data.jobs.forEach(jobs => {
                initialStatusMap[jobs.id] = 'no';

            });
            setSelectedStatusMap(initialStatusMap);
        } catch (error) {
            console.error('Error refreshing job list:', error);
        }
    };

    const deleteJob = (id) => {
        const confirmed = window.confirm('Are you sure you want to delete this Job?');

        if (!confirmed) {
            return;
        }

        axios.delete(`${HOST_ADDRESS}/job/remove/${id}`)
            .then(() => {
                setJobList(jobList.filter(job => job.id !== id));
            })
            .catch(error => {
                console.error('Deleting Job:', error);
            });
    };
    const handleSave = async (jobid) => {
        const selectedStatus = selectedStatusMap[jobid];
        if (selectedStatus && jobid) {
            await changeStatus(jobid, selectedStatus);
            await refreshJobList();
        } else {
            console.error('Invalid selection.');
        }
    };
    const changeStatus = async (jobid, newStatus) => {
        try {
            const statusData = { job_id: jobid, new_status: newStatus };
            const statusUrl = HOST_ADDRESS + '/job/status';
            await axios.post(statusUrl, statusData, { headers: { 'Content-Type': 'application/json' } });

            setSelectedStatusMap(prevMap => ({
                ...prevMap,
                [jobid]: newStatus,
            }));
        } catch (error) {
            console.error('Error changing status:', error);
        }
    };
    const columns = [
        { field: "id", headerName: "Job ID" },
        { field: "title", headerName: "Job Title", flex: 2 },
        { field: "description", headerName: "Job Description", flex: 2 },
        { field: "salary_min", headerName: "Min Salary", flex: 1 },
        { field: "salary_max", headerName: "Max Salary", flex: 1 },
        { field: "no_of_vacancy", headerName: "Vacancy", flex: 1 },
        { field: "exp_min", headerName: "Min Experience", flex: 1 },
        { field: "exp_max", headerName: "Max Experience", flex: 1 },
        { field: "activate", headerName: "Status", flex: 0 },
        { field: "time", headerName: "Created Date", flex: 2 }, 
        { field: "job_expiry", headerName: "Expiry Date", flex: 2 }, 
        {
            field: 'change_status',
            headerName: 'Change Status',
            flex: 2,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', marginTop:'5px', alignItems: 'left', justifyContent: 'left' }}>
                    <Select
                        size='small'
                        value={selectedStatusMap[params.row.id] || ''}
                        variant="outlined"
                        onChange={(event) => {
                            const jobid = params.row.id;
                            const newStatus = event.target.value;
                            setSelectedStatusMap(prevMap => ({
                                ...prevMap,
                                [jobid]: newStatus,
                            }));
                        }}
                    >
                        <MenuItem value="yes">Yes</MenuItem>
                        <MenuItem value="no">No</MenuItem>
                    </Select>
                    <IconButton onClick={() => handleSave(params.row.id)}>
                        <BookmarkAddedOutlinedIcon />
                    </IconButton>
                </Box>
            ),
        },
        {
            field: "Delete_job",
            headerName: "Delete",
            flex: 1,
            renderCell: (params) => (
                <IconButton
                    aria-label="delete"
                    onClick={() => deleteJob(params.row.id)}
                >
                    <DeleteIcon />
                </IconButton>
            )
        },
    ];
    return (
        <Box
            sx={{ m: "15px 15px 15px 15px", width: '96%' }}
            width="98%"
        ><Box
            height='800px'
            sx={{
                overflowY: 'auto',
            }}>

                <Box
                    borderColor={colors.primary[600]}
                    width="100%"
                    padding={4}
                >

                    <Paper elevation={3} sx={{ padding: 3 }}>
                        <Typography
                            variant="h3"
                            gutterBottom
                            sx={
                                { m: "0 0 20px 0" }
                            }
                        >Create Job</Typography>
                        <Formik
                            name='jobForm'
                            onSubmit={submit}
                            initialValues={initialValues}
                            validationSchema={jobSchema}
                        >

                            {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (

                                <form onSubmit={handleSubmit} name='jobForm'>
                                    <Box
                                        display="grid"
                                        gap="30px"
                                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                        sx={{
                                            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" }
                                        }}
                                    >

                                        <TextField
                                            size='small'
                                            fullWidth
                                            variant="outlined"
                                            type='text'
                                            label='Job Title'
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.title}
                                            name='title'
                                            error={!!touched.title && !!errors.title}
                                            helperText={touched.title && errors.title}
                                            sx={{
                                                gridColumn: "span 2",
                                                
                                            }}
                                        />

                                        <TextField
                                            size='small'
                                            fullWidth
                                            variant='outlined'
                                            type='number'
                                            label='Job Expiry'
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.job_expiry}
                                            name='job_expiry'
                                            error={!!touched.job_expiry && !!errors.job_expiry}
                                            helperText={touched.job_expiry && errors.job_expiry}
                                            sx={{
                                                gridColumn: "span 2",
                                                
                                            }}
                                        />

                                        <TextField
                                            size='small'
                                            fullWidth
                                            variant='outlined'
                                            type='text'
                                            label='Job Description'
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.description}
                                            name='description'
                                            error={!!touched.description && !!errors.description}
                                            helperText={touched.description && errors.description}
                                            sx={{
                                                gridColumn: "span 2",
                                                
                                            }}
                                        />
                                        <TextField
                                            size='small'
                                            fullWidth
                                            variant='outlined'
                                            type='number'
                                            label='No of Vacancy'
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.no_of_vacancy}
                                            name='no_of_vacancy'
                                            error={!!touched.no_of_vacancy && !!errors.no_of_vacancy}
                                            helperText={touched.no_of_vacancy && errors.no_of_vacancy}
                                            sx={{
                                                gridColumn: "span 2",
                                                
                                            }}
                                        />
                                        <TextField
                                            size='small'
                                            fullWidth
                                            variant='outlined'
                                            type='number'
                                            label='Minimum Salary'
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.salary_min}
                                            name='salary_min'
                                            error={!!touched.salary_min && !!errors.salary_min}
                                            helperText={touched.salary_min && errors.salary_min}
                                            sx={{
                                                gridColumn: "span 2",
                                                
                                            }}
                                        />
                                        <TextField
                                            size='small'
                                            fullWidth
                                            variant='outlined'
                                            type='number'
                                            label='Maximum Salary'
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.salary_max}
                                            name='salary_max'
                                            error={!!touched.salary_max && !!errors.salary_max}
                                            helperText={touched.salary_max && errors.salary_max}
                                            sx={{
                                                gridColumn: "span 2",
                                                
                                            }}
                                        />
                                        <TextField
                                            size='small'
                                            fullWidth
                                            variant='outlined'
                                            type='number'
                                            label='Minimum Experience'
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.exp_min}
                                            name='exp_min'
                                            error={!!touched.exp_min && !!errors.exp_min}
                                            helperText={touched.exp_min && errors.exp_min}
                                            sx={{
                                                gridColumn: "span 2",
                                                
                                            }}
                                        />
                                        <TextField
                                            size='small'
                                            fullWidth
                                            variant='outlined'
                                            type='text'
                                            label='Maximum Experience'
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.exp_max}
                                            name='exp_max'
                                            error={!!touched.exp_max && !!errors.exp_max}
                                            helperText={touched.exp_max && errors.exp_max}
                                            sx={{
                                                gridColumn: "span 2",
                                                
                                            }}
                                        />

                                        <FormControl variant="outlined" fullWidth sx={{
                                            gridColumn: "span 1",
                                            minHeight: '40px',
                                            '& .MuiOutlinedInput-input': {
                                                padding: '12px',
                                            },
                                        }}>
                                            <InputLabel>Select Skill</InputLabel>
                                            <Select
                                                size='small'
                                                value={selectedSkill}
                                                onChange={(e) => setSelectedSkill(e.target.value)}
                                                label="Select Skill"
                                            >
                                                {skillList.map((skill) => (
                                                    <MenuItem key={skill.id} value={skill.id}>
                                                        {skill.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <Button size='small' variant="contained" color="primary" onClick={addSkill} sx={{ gridColumn: 'span 1', mr: 1 }} >
                                            Add
                                        </Button>
                                        <Typography variant='body1' component='div' sx={{ display: 'flex', gap: '12px', flexWrap: 'wrap', gridColumn: 'span 2' }}>
                                            {addedSkills.map((skillID, index) => {
                                                const foundSkill = skillList.find(skill => skill.id === parseInt(skillID));
                                                return (
                                                    <ListItem
                                                        key={index}
                                                        disableGutters
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            width: 'auto',
                                                            minWidth: '150px',
                                                            borderRadius: '4px',
                                                            border: '1px solid #ccc',
                                                            padding: '4px 8px',
                                                        }}
                                                    >
                                                        <ListItemText sx={{ flex: '1' }}>
                                                            {foundSkill ? `${foundSkill.name}` : `Skill not found (ID: ${skillID})`}
                                                        </ListItemText>
                                                        <IconButton
                                                            aria-label="delete"
                                                            size="small"
                                                            onClick={() => handleRemoveSkill(index)}
                                                            sx={{ marginLeft: '10px' }}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </ListItem>
                                                );
                                            })}
                                        </Typography>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"

                                            sx={{
                                                gridColumn: "span 1",
                                                
                                            }}
                                        >
                                            Submit Form
                                        </Button>
                                    </Box>

                                </form>
                            )}
                        </Formik>
                    </Paper>
                </Box>

                <Box ml="30px" sx={{ width: '97%' }}>
                    <Paper elevation={3} sx={{ padding: 3 }}>
                        <Typography
                            variant="h3"
                            gutterBottom
                            sx={
                                { m: "0 0 20px 0" }
                            }
                        >Job In Database</Typography>
                        <Box m="40px 0 0 0" height="75vh" sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none"
                            },
                            "& .name-column--cell": {
                                color: colors.greenAccent[300]
                            },
                            "& .MuiDataGrid-columnHeader": {
                                backgroundColor: colors.primary[800],
                                borderBottom: "none"
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: colors.primary[400]
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                                backgroundColor: colors.primary[800]
                            },

                        }}>
                            <DataGrid

                                rows={jobList}
                                columns={columns}
                                components={{ Toolbar: GridToolbar }}
                                enableCellSelect={false}
                                suppressRowClickSelection
                                rowsCount={jobList.length}
                                disableSelectionOnClick
                                disableRowSelectionOnClick
                            />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </Box>

    );
};

export default JobAdd;
